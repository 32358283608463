import axios from "axios";
import request from "./index.js";

// const base = "http://122.51.91.25:8090";
const base = "https://demo.luxiaoguo.cn:8090";

// 封装post请求;
function postRequest(url, data = {}) {
  return axios({
    method: "post",
    url: url,
    data: data,
    // 可以添加额外配置，如请求头、响应处理等
    headers: {
      "Content-Type": "application/json",
    },
  });
}

// 用户登录
export const loginFn = (params) => postRequest(base + "/login", params);
// 用户注册
export function userRegister(data) {
  return request({
    url: base + "/register",
    method: "post",
    data,
  });
}

<template>
    <div class="loginContainer">
        <el-form v-if="canLogin" :model="loginForm" :rules="rules" ref="ruleForm" label-position="left"
            label-width="0px" class="login-form-container">
            <div class="login_title">
                <img src="https://xgbcard.xigoubao.com/admin/public/portal/images/login/img_001.png" alt="">
                <span>意霆管理系统</span>
                <img src="https://xgbcard.xigoubao.com/admin/public/portal/images/login/img_002.png" alt="">
            </div>
            <el-form-item prop="userName">
                <el-input class="custom-input" suffix-icon="el-icon-user" type="text" v-model="loginForm.userName"
                    auto-complete="off" placeholder="请输入用户名">
                </el-input>
            </el-form-item>
            <el-form-item prop="checkPass">
                <el-input class="custom-input" :type="passwordType" v-model="loginForm.checkPass" auto-complete="off"
                    @keyup.enter.native="handleEnter" placeholder="请输入密码">
                </el-input>
                <span class="icon" @click="isPasswordVisible = !isPasswordVisible">
                    <i :class="isPasswordVisible ? 'el-icon-unlock' : 'el-icon-lock'"></i>
                </span>
            </el-form-item>
            <!-- <el-checkbox v-model="checked" checked class="remember">记住密码</el-checkbox> -->
            <div style="width:100%;" class="login_btn_box">
                <el-button class="login_btn" type="primary" @click.native.prevent="handleSubmit"
                    :loading="logining">登录</el-button>
                <!-- <el-button @click.native.prevent="handleReset">重置</el-button> -->
                <!-- <span @click="changeCanLogin">没有账户? 立即注册 ></span> -->
            </div>
        </el-form>
        <RegisterPage v-else />
    </div>
</template>

<script>
import RegisterPage from '@/components/registerPage.vue'
import { loginFn } from '@/api/system'
// 引入md5加密库
// import CryptoJS from 'crypto-js'

export default {
    components: {
        RegisterPage
    },
    data() {
        return {
            isPasswordVisible: false,
            loginForm: {
                // userName: 'wanghao111',
                // checkPass: 'abc123'
                userName: '',
                checkPass: ''
            },
            logining: false,
            rules: {
                userName: [
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                ],
                checkPass: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                ]
            },
            canLogin: true,
            checked: true
        }
    },
    mounted() {
    },
    methods: {
        handleEnter() {
            this.handleSubmit()
        },
        handleSubmit() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.logining = true;
                    // md5加密
                    // let encrypted_password = CryptoJS.MD5(this.loginForm.checkPass).toString();
                    // var loginParams = { username: this.loginForm.userName, password: encrypted_password };
                    var loginParams = { username: this.loginForm.userName, password: this.loginForm.checkPass };
                    loginFn(loginParams).then(res => {
                        console.log(res, '登录成功！！！！！')
                        this.logining = false;
                        let { data, status, config } = res;
                        if (status !== 200) {
                            this.$message({
                                message: data.message,
                                type: 'error'
                            });
                        } else {
                            this.$message({
                                message: '登录成功！',
                                type: 'success',
                                showClose: true,
                            })
                            // 登录成功后，调用Vuex action，修改登录状态
                            // this.$store.dispatch('userLogin', true);
                            // "{"username":"wanghao111","password":"abc123"}"
                            // 存储token、用户ID
                            sessionStorage.setItem('token', JSON.stringify(data.token));
                            sessionStorage.setItem('userId', JSON.stringify(data.user_id));
                            // 存储用户名和密码
                            let { username, password } = JSON.parse(config.data)
                            localStorage.setItem('username', username);
                            localStorage.setItem('password', password);
                            // 登录成功后设置默认渲染的页面
                            // this.$router.replace('/home');
                            this.$router.replace('/platOverview');
                        }
                    });
                } else {
                    // console.log('error submit!!');
                    return false;
                }
            });
        },
        handleReset() {
            this.$refs.ruleForm.resetFields();
        },
        changeCanLogin() {
            this.canLogin = false
        }
    },
    computed: {
        passwordType() {
            return this.isPasswordVisible ? 'text' : 'password';
        },
    }
}
</script>
<style lang="less" scoped>
.loginContainer {
    width: 100%;
    height: 100%;
    background-image: url('@/assets/imgs/loginPage/loginbg.jpg');
    background-size: cover;
    position: relative;
    overflow: hidden;

    .login-form-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 438px;
        height: 525px;
        padding: 50px 40px;
        box-sizing: border-box;
        background-image: url('@/assets/imgs/loginPage/login_box.png');
        background-size: 438px 525px;
        background-repeat: no-repeat;

        .login_title {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            font-weight: 400;
            color: #0090ff;
            padding-bottom: 60px;

            img {
                width: 65px;
                height: 12px;
            }

            span {
                margin: 0 30px;
            }
        }

        .el-form-item__content {
            position: relative;

            span.icon {
                position: absolute;
                right: 40px;
                top: 8px;
                cursor: pointer;
            }
        }

        .custom-input /deep/ .el-input__inner {
            width: 304px;
            height: 54px;
            font-size: 16px;
            color: #00E4FF;
            padding-left: 20px;
            padding-right: 45px;
            box-sizing: border-box;
            background-color: rgba(0, 0, 0, 0);
            border: none;
            background-image: url('@/assets/imgs/loginPage/bg_ipt.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            margin: 0 27px 28px;
        }

        /* 修改用户名、密码图标样式 */
        /deep/.el-input__suffix {
            height: 54px;
            right: 40px;
        }

        /deep/.el-icon-user {
            color: #0090ff;
            font-size: 24px;
        }

        /deep/.el-icon-lock,
        /deep/.el-icon-unlock {
            color: #0090ff;
            font-size: 24px;
        }

        /deep/.el-form-item__error {
            color: #F56C6C;
            font-size: 12px;
            line-height: 1;
            position: absolute;
            top: 76%;
            left: 32px;
        }

        .login_btn_box {
            position: relative;

            .login_btn {
                width: 304px;
                height: 54px;
                line-height: 54px;
                font-size: 18px;
                color: #fff;
                background: #3591FF;
                border: none;
                padding: 0;
                margin: 0 27px;
                letter-spacing: 4px;
            }

            span {
                position: absolute;
                bottom: -30px;
                right: 24px;
                color: #0090ff;

                cursor: pointer;

                &:hover {
                    color: #fff;
                }
            }
        }


        .remember {
            margin: 0px 0px 35px 0px;
        }
    }
}
</style>
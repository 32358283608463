import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import Home from "../page/homePage.vue";
import Login from "../page/loginPage.vue";
import AlarmCenter from "../page/alarmCenter.vue";

// 解决报错
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
// 针对 push 方法
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
// 针对 replace 方法
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};

export default new VueRouter({
  routes: [
    {
      path: "/",
      redirect: "/login",
      hidden: true,
    },
    {
      path: "/login",
      component: Login,
      name: "",
      hidden: true,
    },
    {
      path: "/largeScreen",
      component: () => import("@/page/largeScreen.vue"),
    },
    {
      path: "/standardTemplate",
      component: () => import("@/page/standardTemplate.vue"),
    },
    {
      path: "/home", //路径
      name: "", //命名
      component: Home, //注册的页面
      children: [
        //   平台概况
        {
          path: "/platOverview",
          name: "",
          component: () => import("@/page/platOverview.vue"),
        },
        //   GLS大屏
        {
          path: "/glsLargeScreen",
          name: "",
          component: () => import("@/page/glsLargeScreen.vue"),
        },
        // 云组态
        {
          path: "/cloudComponents",
          name: "",
          component: () => import("@/page/cloudComponents.vue"),
          children: [
            //   模板组态
            {
              path: "templateConfiguration",
              name: "",
              component: () => import("@/page/templateConfiguration.vue"),
            },
          ],
        },
        // 报警中心
        {
          path: "/alarmCenter",
          name: "",
          component: AlarmCenter,
          children: [
            //   报警记录
            {
              path: "alarmRecord",
              name: "",
              component: () => import("@/page/alarmRecord.vue"),
            },
            // 报警联系人
            {
              path: "alarmPerson",
              name: "",
              component: () => import("@/page/alarmPerson.vue"),
            },
          ],
        },
        // 数据中心
        {
          path: "/dataCenter",
          name: "",
          component: () => import("@/page/dataCenter.vue"),
          children: [
            //   历史数据
            {
              path: "historyData",
              name: "",
              component: () => import("@/page/historyData.vue"),
            },
          ],
        },
        // 项目中心
        {
          path: "/projectCenter",
          name: "",
          component: () => import("@/page/projectCenter.vue"),
          children: [
            // 项目管理
            {
              path: "projectManage",
              name: "",
              component: () => import("@/page/projectManage.vue"),
            },
          ],
        },
        // 设备中心
        {
          path: "/equipmentCenter",
          name: "",
          component: () => import("@/page/equipmentCenter.vue"),
          children: [
            // 设备管理
            {
              path: "equipmentManage",
              name: "equipmentManage",
              component: () => import("@/page/equipmentManage.vue"),
            },
            // 设备详情
            {
              path: "equipmentDetails",
              name: "equipmentDetails",
              component: () => import("@/page/equipmentDetails.vue"),
            },
          ],
        },
        // 视频监控
        {
          path: "/videoMonitoring",
          name: "",
          component: () => import("@/page/videoMonitoring.vue"),
        },
        // 系统管理
        {
          path: "/systemManage",
          name: "",
          component: () => import("@/page/systemManage.vue"),
          children: [
            // 用户管理
            {
              path: "userManage",
              name: "",
              component: () => import("@/page/userManage.vue"),
            },
            // 角色管理
            {
              path: "roleManage",
              name: "",
              component: () => import("@/page/roleManage.vue"),
            },
            // 个人资料
            {
              path: "userDetails",
              name: "",
              component: () => import("@/page/userDetails.vue"),
            },
          ],
        },
      ],
    },
  ],
});
